import React from 'react'

export function Legend() {
  return (
    <div id="legend">
      <span className="item" id="MARRIAGE">
        <svg width="24" height="4" viewBox="0 0 48 4">
          <line x1="0" y1="0" x2="48" y2="0" stroke="black" strokeWidth="4" strokeDasharray="2,2"></line>
        </svg>
        <span className="label">Married / Partner</span>
      </span>
      <span className="item" id="PARENT_CHILD">
        <svg width="24" height="4" viewBox="0 0 48 4">
          <line x1="0" y1="0" x2="48" y2="0" stroke="black" strokeWidth="4" strokeDasharray="0,0"></line>
        </svg>
        <span className="label">Parent / Child</span>
      </span>
      {/* <span className="item" id="SIBLING">
        <svg width="24" height="4" viewBox="0 0 48 4">
          <line x1="0" y1="0" x2="48" y2="0" stroke="black" strokeWidth="4" strokeDasharray="8,8"></line>
        </svg>
        <span className="label">Sibling</span>
      </span>
      <span className="item" id="PARENT_CHILD_IN_LAW">
        <svg width="24" height="4" viewBox="0 0 48 4">
          <line x1="0" y1="0" x2="48" y2="0" stroke="black" strokeWidth="4" strokeDasharray="4,8,12,8"></line>
        </svg>
        <span className="label">Parent / Child In Law</span>
      </span> */}
    </div>
  )
}