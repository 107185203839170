import { memoise, CHINESE_ZODIAC_SIGNS, getPointOnCircle, MODE } from "../utils"
import { DataLoader } from "./data/dataloader"

export class Background {
  x = 0
  y = 0
  r = 0
  sx = 0
  sy = 0
  sr = 0
  readonly gen: number
  protected readonly dataLoader: DataLoader
  constructor(gen: number, dataLoader: DataLoader) {
    this.gen = gen
    this.dataLoader = dataLoader
  }
  @memoise get decade() {
    return this.dataLoader.decades[this.gen] || -1
  }
  @memoise get month() {
    return this.gen >= 1 && this.gen <= 12 ? new Date(2023, this.gen - 1, 1).toLocaleDateString(undefined, { month: 'long'}) : undefined
  }
  @memoise get zodiac() {
    return this.gen >= 1 && this.gen <= 12 ? CHINESE_ZODIAC_SIGNS[this.gen % CHINESE_ZODIAC_SIGNS.length] : ''
  }
  @memoise get positions(): Record<MODE, { x: number, y: number, r: number }> {
    return {
      generation: this.generationsPosition,
      birthMonth: this.birthMonthPosition,
      birthDecade: this.birthDecadePosition,
      chineseZodiac: this.chineseZodiacPosition,
    }
  }
  @memoise protected get generationsPosition() {
    return {
      x: 0,
      y: 0,
      r: (this.gen * 120 + 5 + 60),
    }
  }
  @memoise protected get birthMonthPosition() {
    return {
      ...getPointOnCircle(Math.PI * 2 / 12 * this.gen, 350),
      r: 75,
    }
  }
  @memoise protected get birthDecadePosition() {
    return {
      x: 0,
      y: 0,
      r: this.gen * 50,
    }
  }
  @memoise protected get chineseZodiacPosition() {
    return {
      ...getPointOnCircle(Math.PI * 2 / 12 * this.gen, 350),
      r: 75,
    }
  }
}