import { DataLoader } from "./data/dataloader"
import { type Link, RELATION_TYPES } from './link'
import { Person } from "./person"
import { type PeopleTSVRow } from "./data/tsv"
import { type MODE, type Point, getPointOnCircle, getOrdinal, CHINESE_ZODIAC_SIGNS, memoise } from "../utils"

export class Node {
  protected readonly dataLoader: DataLoader
  protected visited = false
  protected __generation: number
  readonly person: Person
  placed = false
  x = 0
  y = 0
  sx = 0
  sy = 0
  constructor(person: PeopleTSVRow, dataLoader: DataLoader) {
    this.person = new Person(person)
    this.dataLoader = dataLoader
  }
  setGeneration(generation: number) {
    this.__generation = generation
    this.visited = true
  }
  toString() {
    return `[Node: ${this.person}]`
  }
  @memoise get generation(): number {
    if (!this.visited) {
      this.visited = true
      const parent = this.parents.find(n => n.generation > -1) || this.parentsInLaw.find(n => n.generation > -1)
      if (parent) return parent.generation + 1
      const partner = this.partners.find(n => n.generation > -1)
      if (partner) return partner.generation
      const child = this.children.find(n => n.generation > -1) || this.childrenInLaw.find(n => n.generation > -1)
      if (child) return child.generation - 1
    }
    return this.__generation
  }
  @memoise get parents() {
    return this.dataLoader.parentChildLinks.filter(l => l.target === this).map(l => l.source)
  }
  @memoise get parentsInLaw() {
    return this.dataLoader.inLawLinks.filter(l => l.target === this).map(l => l.source)
  }
  @memoise get children() {
    return this.dataLoader.parentChildLinks.filter(l => l.source === this).map(l => l.target)
  }
  @memoise get childrenInLaw() {
    return this.dataLoader.inLawLinks.filter(l => l.source === this).map(l => l.target)
  }
  @memoise get partners() {
    return this.dataLoader.partnerLinks.filter(l => l.source === this || l.target === this).map(l => l.source === this ? l.target : l.source)
  }
  @memoise get siblings() {
    return this.dataLoader.parentChildLinks.filter(l => this.parents.includes(l.source) && l.target !== this).map(l => l.target)
  }
  @memoise get positions(): Record<MODE, Point> {
    return {
      generation: this.getGenerationPosition,
      birthMonth: this.getBirthMonthPosition,
      birthDecade: this.getBirthDecadePosition,
      chineseZodiac: this.getChineseZodiacPosition,
    }
  }
  getRelationTo(target: Node) {
    const visited = new Set<Node>()
    const queue = [{
      nodes: [this] as Node[],
    }]
    while (queue.length > 0) {
      const item = queue.shift()
      if (item) {
        const { nodes } = item
        const node = nodes[nodes.length - 1]
        if (!visited.has(node)) {
          visited.add(node)
          if (node === target) {
            const links = nodes.map((n, i, arr) => {
              return this.dataLoader.links.find(l => (
                (l.source === n && l.target === arr[i + 1])
                || (l.target === n && l.source === arr[i + 1])
              )) || this.dataLoader.siblingLinks.find(l => (
                (l.source === n && l.target === arr[i + 1])
                || (l.target === n && l.source === arr[i + 1])
              )) as Link
            }).filter(l => !!l)
            return { nodes, links }
          }
          node.children.forEach(node => queue.push({ nodes: [...nodes, node] }))
          node.parents.forEach(node => queue.push({ nodes: [...nodes, node] }))
          node.partners.forEach(node => queue.push({ nodes: [...nodes, node] }))
          // node.siblings.forEach(node => queue.push({ nodes: [...nodes, node] }))
        }
      }
    }
  }
  getRelationStringTo(target: Node) {
    const parentMap = {
      'Male': 'father',
      'Female': 'mother',
      'Other': 'parent',
    }
    const childMap = {
      'Male': 'son',
      'Female': 'daughter',
      'Other': 'child',
    }
    const siblingChildMap = {
      'Male': 'nephew',
      'Female': 'niece',
      'Other': 'sibling-child',
    }
    const siblingParentMap = {
      'Male': 'uncle',
      'Female': 'aunt',
      'Other': 'sibling-parent',
    }
    const siblingMap = {
      'Male': 'brother',
      'Female': 'sister',
      'Other': 'sibling',
    }
    function isParentChild(l) {
      return l.type === RELATION_TYPES.PARENT_CHILD
    }
    if (this === target) {
      return 'self'
    }
    if (this.siblings.includes(target)) {
      return siblingMap[this.person.gender]
    }
    if (this.partners.includes(target)) {
      return 'partner'
    }
    const { links } = this.getRelationTo(target)
    const isDirectRelation = links.every(isParentChild)
    const isInLaw = (links[0].type === RELATION_TYPES.MARRIAGE && links.slice(1).every(isParentChild))
      || (links[links.length - 1].type === RELATION_TYPES.MARRIAGE && links.slice(0, -1).every(isParentChild))
    const genDiff = target.generation - this.generation
    const absGenDiff = Math.abs(genDiff)
    if (isDirectRelation) {
      if (absGenDiff === links.length) {
        let str = (genDiff > 0 ? parentMap : genDiff === 0 ? siblingMap : childMap)[this.person.gender]
        if (absGenDiff === 1) str = '' + str
        if (absGenDiff >= 2) str = 'grand-' + str
        if (absGenDiff >= 3) str = 'great-'.repeat(absGenDiff - 2) + str
        return str
      }
      if (absGenDiff + 2 === links.length) {
        let str = (genDiff > 0 ? siblingParentMap : genDiff === 0 ? siblingMap : siblingChildMap)[this.person.gender]
        if (absGenDiff === 1) str = '' + str
        if (absGenDiff >= 2) str = 'grand-' + str
        if (absGenDiff >= 3) str = 'great-'.repeat(absGenDiff - 2) + str
        return str
      }
    }
    if (isInLaw) {
      const rest = links.filter(isParentChild)
      if (absGenDiff === rest.length) {
        let str = (genDiff > 0 ? parentMap : genDiff === 0 ? siblingMap : childMap)[this.person.gender]
        if (absGenDiff === 1) str = '' + str
        if (absGenDiff >= 2) str = 'grand-' + str
        if (absGenDiff >= 3) str = 'great-'.repeat(absGenDiff - 2) + str
        return str + '-in-law'
      }
      if (absGenDiff + 2 === rest.length) {
        let str = (genDiff > 0 ? siblingParentMap : genDiff === 0 ? siblingMap : siblingChildMap)[this.person.gender]
        if (absGenDiff === 1) str = '' + str
        if (absGenDiff >= 2) str = 'grand-' + str
        if (absGenDiff >= 3) str = 'great-'.repeat(absGenDiff - 2) + str
        return str + '-in-law'
      }
    }
    const commonAncestor = links.reduce((acc, link) => {
      if (link.source.generation < acc.generation) return link.source
      if (link.target.generation < acc.generation) return link.target
      return acc
    }, links[0].source)
    const sourceAncDiff = Math.abs(commonAncestor.generation - this.generation)
    const targetAncDiff = Math.abs(commonAncestor.generation - target.generation)
    const isCousins = isDirectRelation && sourceAncDiff >= 2 && targetAncDiff >= 2
    if (isCousins) {
      const degree = Math.min(sourceAncDiff, targetAncDiff) - 1
      const removal = Math.abs(sourceAncDiff - targetAncDiff)
      const removalString = {
        [1]: 'once',
        [2]: 'twice',
      }[removal] || `${removal} times`
      return `${getOrdinal(degree)} cousin` + (removal ? ` ${removalString} removed` : '')
    }
    return 'unknown'
  }
  @memoise protected get getGenerationPosition() {
    const genNodes = this.dataLoader.nodesByGeneration.get(this.generation)
    if (genNodes) {
      const genStep = 120
      const i = genNodes.indexOf(this)
      const radStep = Math.PI * 2 / genNodes.length
      // const radStep = Math.PI * 2 / this.dataLoader.maxGenSize
      const start = Math.PI * -0.5 + this.generation * Math.PI * 0.2
      return getPointOnCircle(start + radStep * i, this.generation * genStep)
    }
    return { x: 0, y: 0 }
  }
  @memoise protected get getBirthMonthPosition() {
    const birthMonth = this.person.birthMonth
    const nodes = this.dataLoader.nodesByBirthMonth.get(birthMonth)
    if (nodes && birthMonth > -1) {
      const sm = Math.PI * 2 / 12
      const start = Math.PI * -0.5
      const pm = getPointOnCircle(sm * birthMonth, 350)
      const sn = Math.PI * 2 / nodes.length
      const index = nodes.indexOf(this)
      const pn = getPointOnCircle(start + sn * index, 75)
      const x = pm.x + pn.x
      const y = pm.y + pn.y
      return { x, y }
    }
    return { x: 0, y: 0 }
  }
  @memoise protected get getBirthDecadePosition() {
    const birthDecade = this.person.birthDecade
    const nodes = this.dataLoader.nodesByDecade.get(birthDecade)
    if (nodes && birthDecade > -1) {
      const i = this.dataLoader.decades.indexOf(birthDecade)
      const r = i * 50
      const start = Math.PI * -0.5 + birthDecade
      const sn = Math.PI * 2 / nodes.length
      const index = nodes.indexOf(this)
      const pn = getPointOnCircle(start + sn * index, r)
      const x = pn.x
      const y = pn.y
      return { x, y }
    }
    return { x: 0, y: 0 }
  }
  @memoise protected get getChineseZodiacPosition() {
    const sign = this.person.chineseZodiacSign
    const nodes = this.dataLoader.nodesByZodiacSign.get(sign)
    if (nodes && sign) {
      const sm = Math.PI * 2 / 12
      const start = Math.PI * -0.5
      const pm = getPointOnCircle(sm * CHINESE_ZODIAC_SIGNS.indexOf(sign), 350)
      const sn = Math.PI * 2 / nodes.length
      const index = nodes.indexOf(this)
      const pn = getPointOnCircle(start + sn * index, 75)
      const x = pm.x + pn.x
      const y = pm.y + pn.y
      return { x, y }
    }
    return { x: 0, y: 0 }
  }
}