import { zoom, select, ZoomBehavior } from 'd3'
import { memoise } from '../utils'

type StylingFunction = (ctx: CanvasRenderingContext2D) => void

export class Renderer {
  protected readonly canvas: HTMLCanvasElement
  protected width: number
  protected height: number
  protected panX: number
  protected panY: number
  protected scale: number
  protected pixelScale: number
  protected zoomMgr: ZoomBehavior<HTMLCanvasElement, unknown>
  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas
    window.addEventListener('resize', this.onResize)
    this.onResize()
    this.zoomMgr = this.createZoomManager()
  }
  @memoise protected get ctx() {
    return this.canvas.getContext('2d')
  }
  clear(isDarkMode = false) {
    this.ctx.resetTransform()
    this.ctx.fillStyle = isDarkMode ? 'black' : 'whitesmoke'
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
    this.ctx.translate(this.canvas.width / 2, this.canvas.height / 2)
    this.ctx.translate(this.panX, this.panY)
    this.ctx.scale(this.pixelScale, this.pixelScale)
    this.ctx.scale(this.scale, this.scale)
  }
  protected onResize = () => {
    this.pixelScale = Math.max(1.25, window.devicePixelRatio || 1)
    this.width = window.innerWidth
    this.height = window.innerHeight
    this.canvas.width = this.width * this.pixelScale
    this.canvas.height = this.height * this.pixelScale
    this.clear()
  }
  translateToPixelSpace(x: number, y: number) {
    const t = this.ctx.getTransform()
    return {
      x: x / (t.a / this.pixelScale) - t.e / t.a,
      y: y / (t.a / this.pixelScale) - t.f / t.a,
    }
  }
  protected save(callback: () => void) {
    this.ctx.save()
    callback()
    this.ctx.restore()
  }
  circle = (x: number, y: number, radius: number, style?: StylingFunction) => {
    this.save(() => {
      this.ctx.beginPath()
      this.ctx.arc(x, y, radius, 0, Math.PI * 2)
      style?.(this.ctx)
      this.ctx.fill()
      this.ctx.stroke()
      this.ctx.closePath()
    })
  }
  line = (x1: number, y1: number, x2: number, y2: number, style?: StylingFunction) => {
    this.save(() => {
      this.ctx.beginPath()
      this.ctx.moveTo(x1, y1)
      this.ctx.lineTo(x2, y2)
      style?.(this.ctx)
      this.ctx.stroke()
      this.ctx.closePath()
    })
  }
  text = (x: number, y: number, text: string, style?: StylingFunction) => {
    this.save(() => {
      style?.(this.ctx)
      this.ctx.fillText(text, x, y)
    })
  }
  protected createZoomManager(canvas = this.canvas) {
    const zoomMgr = zoom<HTMLCanvasElement, unknown>()
    zoomMgr.scaleExtent([0.125, 10])
    zoomMgr.on('zoom', event => {
      this.panX = event.transform.x
      this.panY = event.transform.y
      this.scale = event.transform.k
    })
    zoomMgr.filter(event => {
      if (event.type === 'dblclick') {
        return false
      }
      return true
    })
    select(canvas).call(zoomMgr)
    return zoomMgr
  }
}