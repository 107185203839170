import { PEOPLE_TSV, RELATIONS_TSV } from './tsv'
import { Link, RELATION_TYPES } from '../link'
import { Node } from '../node'
import { memoise, overlap, tokenise } from '../../utils'

export class DataLoader {
  constructor() {
    this.reportOnNoGens()
  }
  @memoise protected get baseNodes() {
    const nodes = PEOPLE_TSV.map(person => new Node(person, this))
    const oldest = nodes.reduce((acc, node) => {
      return node.person.birthDate < acc.person.birthDate ? node : acc
    })
    oldest.setGeneration(1)
    return nodes
  }
  @memoise get nodes() {
    const out = new Set<Node>()
    function place(node: Node) {
      if (!node.placed) {
        node.placed = true
        out.add(node)
        node.partners.forEach(place)
      }
    }
    for (const node of this.baseNodes) {
      place(node)
      node.children.forEach(place)
    }
    return [...out]
  }
  @memoise get links() {
    return RELATIONS_TSV.map(r => {
      const source = this.baseNodes.find(node => node.person.name === r.Source)
      const target = this.baseNodes.find(node => node.person.name === r.Target)
      const type = r.Type === 'Parent/Child' ? RELATION_TYPES.PARENT_CHILD : RELATION_TYPES.MARRIAGE
      if (!(source && target)) throw new Error('Person referenced in link is missing!')
      return new Link(source, target, type)
    })
  }
  @memoise get parentChildLinks() {
    return this.links.filter(l => l.type === RELATION_TYPES.PARENT_CHILD)
  }
  @memoise get partnerLinks() {
    return this.links.filter(l => l.type === RELATION_TYPES.MARRIAGE)
  }
  @memoise get inLawLinks() {
    return this.partnerLinks.reduce((acc, pl) => ([
      ...acc,
      ...pl.target.parents.map(p => new Link(p, pl.source, RELATION_TYPES.PARENT_CHILD_IN_LAW)),
      ...pl.source.parents.map(p => new Link(p, pl.target, RELATION_TYPES.PARENT_CHILD_IN_LAW)),
    ]), [] as Link[])
  }
  @memoise get siblingLinks() {
    const visited = new Set<Node>()
    return this.nodes.reduce((acc, node) => {
      if (!visited.has(node)) {
        visited.add(node)
        node.siblings.forEach(sib => {
          if (!visited.has(sib)) {
            acc.push(new Link(node, sib, RELATION_TYPES.SIBLING))
          }
        })
      }
      return acc
    }, [] as Link[])
  }
  @memoise get nodesByGeneration() {
    return this.nodes.reduce((acc, node) => {
      if (!acc.has(node.generation)) acc.set(node.generation, [])
      acc.get(node.generation)?.push(node)
      return acc
    }, new Map<number, Node[]>)
  }
  @memoise get nodesByDecade() {
    return this.nodes.reduce((acc, node) => {
      if (node.person.birthDecade) {
        if (!acc.has(node.person.birthDecade)) acc.set(node.person.birthDecade, [])
        acc.get(node.person.birthDecade)?.push(node)
      }
      return acc
    }, new Map<number, Node[]>)
  }
  @memoise get nodesByBirthMonth() {
    return this.nodes.reduce((acc, node) => {
      if (node.person.birthMonth > -1) {
        if (!acc.has(node.person.birthMonth)) acc.set(node.person.birthMonth, [])
        acc.get(node.person.birthMonth)?.push(node)
      }
      return acc
    }, new Map<number, Node[]>)
  }
  @memoise get nodesByZodiacSign() {
    return this.nodes.reduce((acc, node) => {
      if (node.person.chineseZodiacSign) {
        if (!acc.has(node.person.chineseZodiacSign)) acc.set(node.person.chineseZodiacSign, [])
        acc.get(node.person.chineseZodiacSign)?.push(node)
      }
      return acc
    }, new Map<string, Node[]>)
  }
  @memoise get decades() {
    return [...this.nodesByDecade.keys()].sort()
  }
  @memoise get highestGen() {
    return Math.max(...this.nodesByGeneration.keys())
  }
  @memoise get maxGen() {
    return Math.max(12, this.highestGen, this.decades.length)
  }
  @memoise get maxGenSize() {
    return [...this.nodesByGeneration].reduce((acc, [gen, nodes]) => Math.max(nodes.length, acc), 0)
  }
  protected reportOnNoGens() {
    const noGens = this.nodes.filter(node => node.generation === -1)
    const noGenLinks = this.links.filter(link => {
      return noGens.includes(link.source) || noGens.includes(link.target)
    })
    console.debug(
      'Nodes with generation -1',
      noGens.map(node => node.person.name),
      noGenLinks,
    )
  }
  findNodeByPosition(x: number, y: number, radius: number) {
    return this.nodes.find(node => {
      const dx = node.x - x
      const dy = node.y - y
      const ds = dx * dx + dy * dy
      return node.generation > 0 && ds < radius
    })
  }
  findNodesByPartialName(value: string) {
    const t = tokenise(value)
    if (t.length === 0) return []
    return this.nodes.map(node => ({
      node, score: overlap(node.person.tokenisedName, t)
    })).filter(r => r.score).sort((a, b) => b.score - a.score).map(r => r.node)
  }
}

