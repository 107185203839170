import React, { useContext } from 'react'
import { ControlContext } from './provider'

export function ModeSelectors() {
  const { setMode, setRelationMode } = useContext(ControlContext)
  const onChangeMode = event => event.target.checked && setMode?.(event.target.value)
  const onChangeRelationMode = event => setRelationMode?.(event.target.checked)
  return (
    <>
      <legend>Group by</legend>
      <fieldset>
        <label><input type="radio" name="groupby" value="generation" onChange={onChangeMode} defaultChecked />generation</label>
        <label><input type="radio" name="groupby" value="birthMonth" onChange={onChangeMode} />birth month</label>
        <label><input type="radio" name="groupby" value="birthDecade" onChange={onChangeMode} />birth decade</label>
        <label><input type="radio" name="groupby" value="chineseZodiac" onChange={onChangeMode} />Chinese zodiac</label>
      </fieldset>
      {/* <label><input type="checkbox" name="relation-mode" id="relation-mode" onChange={onChangeRelationMode} defaultChecked />Relation finder</label> */}
    </>
  )
}