export type PeopleTSVRow = {
  'Full Name': string,
  'Nickname': string,
  'Gender': string,
  'Date of Birth': string,
  'Date of Death': string,
}

export type RelationsTSVRow = {
  Source: string,
  Target: string,
  Type: 'Parent/Child' | 'Marriage/Partner',
}

function splitTsv<T extends Record<string, string>>(str: string, hasHeaderRow = false): T[] {
  const raw = str.trim().split('\r\n')
  const deduped = raw.reduce((acc, row) => {
    if (!acc.has(row)) {
      acc.add(row)
    }
    return acc
  }, new Set<string>())
  const rows = [...deduped.values()]
  console.debug('TSV split', { raw: raw.length, deduped: deduped.size })
  if (hasHeaderRow) {
    const [headerRow, ...contentRows] = rows
    const headers = headerRow.trim().split('\t')
    return contentRows.map(row => {
      const cells = row.trim().split('\t')
      return headers.reduce((acc, header, i) => {
        return {
          ...acc,
          [header]: cells[i]?.trim()
        }
      }, {} as T)
    })
  }
  return rows.map(row => {
    const cells = row.trim().split('\t')
    return Array(cells.length).fill(null).map((v, i) => i).reduce((acc, header, i) => {
      return {
        ...acc,
        [header]: cells[i]?.trim()
      }
    }, {} as T)
  })
}

// @ts-ignore
export const PEOPLE_TSV = splitTsv<PeopleTSVRow>(require('./Family Tree in Tuple Form - People.tsv'), true)

// @ts-ignore
export const RELATIONS_TSV = splitTsv<RelationsTSVRow>(require('./Family Tree in Tuple Form - Relations.tsv'), true)