import { Link } from "./data/link"
import { Node } from "./data/node"

export function getPointOnCircle(angleInRadians: number, radius: number) {
  const x = radius * Math.cos(angleInRadians)
  const y = radius * Math.sin(angleInRadians)
  return { x, y }
}

export type Point = ReturnType<typeof getPointOnCircle>

export const CHINESE_ZODIAC_SIGNS = [
  'Rat',
  'Ox',
  'Tiger',
  'Rabbit',
  'Dragon',
  'Snake',
  'Horse',
  'Sheep',
  'Monkey',
  'Rooster',
  'Dog',
  'Pig',
]

const CHINESE_ZODIAC_ELEMENTS = [
  'Wood',
  'Fire',
  'Earth',
  'Metal',
  'Water',
]

export const SEXAGENARY_YEARS = Array(CHINESE_ZODIAC_SIGNS.length * CHINESE_ZODIAC_ELEMENTS.length).fill(null).map((_, i) => {
  const sign = CHINESE_ZODIAC_SIGNS[i % CHINESE_ZODIAC_SIGNS.length]
  const element = CHINESE_ZODIAC_ELEMENTS[Math.floor(i / 2) % CHINESE_ZODIAC_ELEMENTS.length]
  return { element, sign }
})

export function parseDate(str: string) {
  const [day, month, year] = (str || '').split('/')
  if (day && month && year) {
    const date = new Date()
    date.setDate(Number(day))
    date.setMonth(Number(month) - 1)
    date.setFullYear(Number(year))
    return date
  }
}

export function formatDate(date?: Date, isMobile = false) {
  if (date) {
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: isMobile ? 'short' : 'long',
      day: 'numeric',
      weekday: isMobile ? 'short' : 'long',
    })
  }
  return ''
}

export function getOrdinal(i: number) {
  const suffixes = new Intl.PluralRules('en', { type: 'ordinal' }).select(i);
  switch (suffixes) {
    case 'one':
      return i + 'st';
    case 'two':
      return i + 'nd';
    case 'few':
      return i + 'rd';
    default:
      return i + 'th';
  }
}

export function memoise(target: any, key: string, descriptor: PropertyDescriptor) {
  const oldGetter = descriptor.get
  if (oldGetter && oldGetter.length === 0) {
    const memoKey = `__${key}`
    const newGetter = function() {
      return this[memoKey] ?? (this[memoKey] = oldGetter.call(this))
    }
    return { ...descriptor, get: newGetter }
  }
}

export type MODE = 'generation'
  | 'birthMonth'
  | 'birthDecade'
  | 'chineseZodiac'

export type MinimalControls = {
  mode: MODE
  active: Node
  activeLinks: Link[]
  isDarkMode: boolean
}

export function tokenise(str: string) {
  return str.toLocaleLowerCase().trim().split('').reduce((acc, l, i, arr) => {
    return [...acc, arr[i] + arr[i + 1] + arr[i + 2]]
  }, [] as string[]).slice(0, -2)
}

export function overlap(t1: string[], t2: string[]) {
  return t1.reduce((acc, i) => acc + (t2.includes(i) ? 1 : 0), 0)
}