import { color, interpolateRainbow } from 'd3'
import { type Node } from './node'
import { type DataLoader } from "./data/dataloader"
import { type MinimalControls, CHINESE_ZODIAC_SIGNS } from '../utils'
import { type Renderer } from "../ui/renderer"
import { easeExp } from "d3"

export class Nodes {
  protected dataLoader: DataLoader
  protected renderer: Renderer
  protected controls: MinimalControls
  constructor(dataLoader: DataLoader, renderer: Renderer, controls: MinimalControls) {
    this.dataLoader = dataLoader
    this.renderer = renderer
    this.controls = controls
  }
  draw(normT: number) {
    const ease = easeExp(normT)
    const { nodes } = this.dataLoader
    const { circle, text } = this.renderer
    for (const node of nodes) {
      if (normT === 0) {
        node.sx = node.x
        node.sy = node.y
      }
      const p = node.positions[this.controls.mode]
      node.x = node.sx + ease * (p.x - node.sx)
      node.y = node.sy + ease * (p.y - node.sy)
      circle(node.x, node.y, 5, ctx => {
        ctx.strokeStyle = 'transparent'
        ctx.fillStyle = this.getColour(node)
      })
      if (normT === 1) {
        text(0, 0, node.person.shortName, ctx => {
          ctx.font = 'normal 12px sans-serif'
          if (this.isActive(node)) {
            ctx.font = 'bold 12px sans-serif'
            ctx.shadowColor = 'rgba(0, 0, 0, 0.3)'
            ctx.shadowOffsetX = 2
            ctx.shadowOffsetY = 2
            ctx.shadowBlur = 8
          }
          ctx.textBaseline = 'middle'
          ctx.translate(node.x, node.y)
          const r = Math.atan2(node.y, node.x)
          if (r > Math.PI * 0.5 || r < Math.PI * -0.5) {
            ctx.rotate(r + Math.PI)
            ctx.textAlign = 'end'
            ctx.translate(-8, 0)
          } else {
            ctx.rotate(r)
            ctx.translate(8, 0)
          }
          ctx.fillStyle = this.getColour(node, true)
        })
      }
    }
  }
  protected getColour(node: Node, fadeOut = false) {
    const mult = this.controls.isDarkMode ? 0 : 0.4
    const { mode } = this.controls
    const { maxGen, decades } = this.dataLoader
    if (node.generation > 0) {
      if (mode === 'generation') {
        const gen = node.generation
        const colour = color(interpolateRainbow(gen / maxGen))?.darker(mult)
        if (colour) {
          colour.opacity = fadeOut ? this.isActive(node) ? 1 : 0.6 : 1
          return colour.toString()
        }
      } else if (mode === 'birthMonth') {
        const month = node.person.birthMonth
        if (month > 0) {
          const colour = color(interpolateRainbow(month / 12))?.darker(mult)
          if (colour) {
            colour.opacity = fadeOut ? this.isActive(node) ? 1 : 0.6 : 1
            return colour.toString()
          }
        }
      } else if (mode === 'birthDecade') {
        const decade = decades.indexOf(node.person.birthDecade)
        if (decade > -1) {
          const colour = color(interpolateRainbow(decade / maxGen))?.darker(mult)
          if (colour) {
            colour.opacity = fadeOut ? this.isActive(node) ? 1 : 0.6 : 1
            return colour.toString()
          }
        }
      } else if (mode === 'chineseZodiac') {
        const sign = CHINESE_ZODIAC_SIGNS.indexOf(node.person.chineseZodiacSign)
        if (sign > -1) {
          const colour = color(interpolateRainbow(sign / CHINESE_ZODIAC_SIGNS.length))?.darker(mult)
          if (colour) {
            colour.opacity = fadeOut ? this.isActive(node) ? 1 : 0.6 : 1
            return colour.toString()
          }
        }
      }
    }
    return 'transparent'
  }
  protected isActive(node: Node) {
    if (this.controls.active === node) {
      return true
    }
    if (this.controls.mode === 'generation' || this.controls.mode === 'birthDecade') {
      const linksToActive = this.controls.activeLinks.find(link => link.source === node || link.target === node)
      if (linksToActive) {
        return true
      }
    }
    return false
  }
}