import React, { useState, useEffect, createContext } from 'react'
import { type Node } from '../data/node'
import { type MODE } from '../utils'
import { DataLoader } from '../data/data/dataloader'

type ControlState = {
  node?: Node
  setNode?: React.Dispatch<React.SetStateAction<Node | undefined>>
  mode: MODE
  setMode?: React.Dispatch<React.SetStateAction<MODE>>
  baseNode: Node
  setBaseNode?: React.Dispatch<React.SetStateAction<Node | undefined>>
  relationMode: boolean
  setRelationMode?: React.Dispatch<React.SetStateAction<boolean>>
  isDarkMode: boolean
  isMobile: boolean
  dataLoader: DataLoader
}

const dataLoader = new DataLoader()

export const ControlContext = createContext({} as ControlState)

export function ControlProvider({ children }: { children: React.ReactNode }) {
  const [node, setNode] = useState<Node | undefined>()
  const [baseNode, setBaseNode] = useState<Node | undefined>()
  const [mode, setMode] = useState<MODE>('generation')
  const [isDarkMode, setIsDarkMode] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [relationMode, setRelationMode] = useState(true)
  useEffect(() => {
    const match = window.matchMedia?.('(prefers-color-scheme: dark)')
    if (match) {
      setIsDarkMode(match.matches)
      match.addEventListener('change', event => setIsDarkMode(event.matches))
    }
  }, [])
  useEffect(() => {
    const match = window.matchMedia?.('(max-width: 667px)')
    if (match) {
      setIsMobile(match.matches)
      match.addEventListener('change', event => setIsMobile(event.matches))
    }
  }, [])
  const state = { node, setNode, mode, setMode, relationMode, setRelationMode, isDarkMode, isMobile, dataLoader, baseNode, setBaseNode }
  return (
    <ControlContext.Provider value={state}>
      {children}
    </ControlContext.Provider>
  )
}