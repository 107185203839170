import { timeDay, timeYear } from 'd3'
import { type PeopleTSVRow } from "./data/tsv"
import { parseDate, SEXAGENARY_YEARS, memoise, tokenise } from "../utils"

enum GENDER {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}

export class Person {
  readonly name: string
  readonly gender: GENDER
  readonly nickname: string
  readonly birthDate?: Date
  readonly deathDate?: Date
  constructor(person: PeopleTSVRow) {
    this.name = person['Full Name']
    this.gender = person.Gender as GENDER
    this.nickname = person.Nickname
    this.birthDate = parseDate(person['Date of Birth'])
    this.deathDate = parseDate(person['Date of Death'])
  }
  toString() {
    return `[Person: ${this.shortName}]`
  }
  @memoise get shortName() {
    return this.name.split(' ').filter((str, i, arr) => i === 0 || i === arr.length - 1).join(' ')
  }
  @memoise get normalisedName() {
    return this.name.toLocaleLowerCase().trim()
  }
  @memoise get tokenisedName() {
    return tokenise(this.name)
  }
  @memoise get birthMonth() {
    return this.birthDate && this.birthDate.getMonth() + 1
  }
  @memoise get birthDecade() {
    return this.birthDate && Math.floor(this.birthDate.getFullYear() / 10) * 10
  }
  @memoise get chineseZodiacSign() {
    if (this.birthDate) {
      const zodiacOffset = timeDay.count(timeYear.floor(this.birthDate), this.birthDate) < 35 ? 1 : 0 // change this to 1 for people born before the Chinese New Year -- if you can figure that out.
      const zodiac = (this.birthDate.getFullYear() - 4 - zodiacOffset) % SEXAGENARY_YEARS.length
      return SEXAGENARY_YEARS[zodiac].sign
    }
  }
}