import { color, interpolateRainbow, easeExp } from 'd3'
import { type DataLoader } from "./data/dataloader"
import { type MODE, type MinimalControls, memoise } from '../utils'
import { type Renderer } from "../ui/renderer"
import { Background } from './background'

export class Backgrounds {
  protected dataLoader: DataLoader
  protected renderer: Renderer
  protected controls: MinimalControls
  protected static lineDashMap: Record<MODE, number[]> = {
    generation: [10, 10],
    birthMonth: [5, 5],
    birthDecade: [32, 32],
    chineseZodiac: [5, 5],
  }
  constructor(dataLoader: DataLoader, renderer: Renderer, controls: MinimalControls) {
    this.dataLoader = dataLoader
    this.renderer = renderer
    this.controls = controls
  }
  draw(normT: number) {
    const ease = easeExp(normT)
    const { circle, text } = this.renderer
    for (const bg of this.backgrounds) {
      if (normT === 0) {
        bg.sx = bg.x
        bg.sy = bg.y
        bg.sr = bg.r
      }
      const t = bg.positions[this.controls.mode]
      bg.x = bg.sx + ease * (t.x - bg.sx)
      bg.y = bg.sy + ease * (t.y - bg.sy)
      bg.r = bg.sr + ease * (t.r - bg.sr)
      circle(bg.x, bg.y, bg.r, ctx => {
        const dash = Backgrounds.lineDashMap[this.controls.mode] || []
        ctx.setLineDash(dash)
        ctx.fillStyle = 'transparent'
        ctx.strokeStyle = this.getColour(bg)
      })
      if (normT === 1) {
        if (this.controls.mode === 'birthMonth' && bg.month) {
          text(bg.x, bg.y, bg.month, ctx => {
            this.textStyle(ctx, bg)
          })
        } else if (this.controls.mode === 'birthDecade' && bg.decade) {
          text(0, 0, `${bg.decade}s`, ctx => {
            this.textStyle(ctx, bg)
            ctx.translate(0, bg.positions.birthDecade.r)
          })
        } else if (this.controls.mode === 'chineseZodiac' && bg.zodiac) {
          text(bg.x, bg.y, bg.zodiac, ctx => {
            this.textStyle(ctx, bg)
          })
        }
      }
    }
  }
  protected textStyle(ctx: CanvasRenderingContext2D, bg: Background) {
    ctx.font = 'bold 12px sans-serif'
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'
    ctx.fillStyle = this.getColour(bg)
    ctx.shadowColor = 'rgba(0, 0, 0, 0.3)'
    ctx.shadowOffsetX = 2
    ctx.shadowOffsetY = 2
    ctx.shadowBlur = 8
  }
  @memoise protected get backgrounds() {
    const { maxGen } = this.dataLoader
    return Array(maxGen).fill(undefined).map((_, i) => new Background(i, this.dataLoader))
  }
  protected getColour(bg: Background) {
    const mult = this.controls.isDarkMode ? 0 : 0.6
    const { mode } = this.controls
    const { maxGen, highestGen, decades } = this.dataLoader
    if (bg.gen > 0) {
      if (mode === 'generation') {
        const gen = bg.gen
        if (gen <= highestGen) {
          const colour = color(interpolateRainbow(gen / maxGen))?.darker(mult).toString()
          if (colour) return colour
        }
      } else if (mode === 'birthMonth' || mode === 'chineseZodiac') {
        const month = bg.month
        if (month) {
          const colour = color(interpolateRainbow(bg.gen / 12))?.darker(mult).toString()
          if (colour) return colour
        }
      } else if (mode === 'birthDecade') {
        const decade = decades.indexOf(bg.decade)
        if (decade > -1) {
          const colour = color(interpolateRainbow(decade / maxGen))?.darker(mult).toString()
          if (colour) return colour
        }
      }
    }
    return 'transparent'
  }
}