import React, { useState, useEffect, useContext } from 'react'
import { type Node } from '../data/node'
import { ControlContext } from './provider'

export function SearchPanel() {
  const { setNode, dataLoader } = useContext(ControlContext)
  const [value, setValue] = useState('')
  const [nodes, setNodes] = useState<Node[]>([])
  const [index, setIndex] = useState(0)
  const onSubmit = event => {
    event.preventDefault()
    const newValue = new FormData(event.target).get('name')
    if (typeof newValue === 'string') {
      setValue(newValue)
      if (value === newValue) {
        setIndex(prev => (prev + 1) % nodes.length)
      }
    }
  }
  useEffect(() => {
    setNode(nodes[index])
  }, [index, nodes])
  useEffect(() => {
    const nodes = dataLoader.findNodesByPartialName(value)
    setNodes(nodes)
    setIndex(0)
  }, [value])
  return (
    <form id="search" onSubmit={onSubmit}>
      <input autoComplete="off" name="name" type="text" placeholder="Search by name..." />
    </form>
  )
}