import React, { useState } from 'react'
import { Legend } from './legend'
import { InfoPanel } from './infopanel'
import { SearchPanel } from './search'
import { ModeSelectors } from './modeselectors'

export function ControlUI() {
  const [show, setShow] = useState(false)
  const toggleShow = () => setShow(prev => !prev)
  return (
    <div id="infobox">
      <InfoPanel />
      <button id='form-toggle' onClick={toggleShow}>
        <svg width="24" height="24" viewBox="0 0 24 24">
          <line x1="0" y1="0" x2="100%" y2="0" stroke="black" strokeWidth="4" transform="translate(0 4)"></line>
          <line x1="0" y1="50%" x2="100%" y2="50%" stroke="black" strokeWidth="4" transform="translate(0 0)"></line>
          <line x1="0" y1="100%" x2="100%" y2="100%" stroke="black" strokeWidth="4" transform="translate(0 -4)"></line>
        </svg>
      </button>
      <dialog open={show}>
        <SearchPanel />
        <ModeSelectors />
        <Legend />
      </dialog>
    </div>
  )
}