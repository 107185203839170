import React from 'react'
import { createRoot } from 'react-dom/client'
import { ControlProvider } from './ui/provider'
import { ControlUI } from './ui/controls'
import { Canvas } from './ui/canvas'

function App() {
  return (
    <ControlProvider>
      <Canvas />
      <ControlUI />
    </ControlProvider>
  )
}

const root = createRoot(document.querySelector('#app'))
root.render(<App />)