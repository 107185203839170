import React, { useRef, useEffect, useContext } from 'react'
import { ControlContext } from './provider'
import { Animator } from './animator'
import { Renderer } from './renderer'
import { Nodes } from '../data/nodes'
import { Backgrounds } from '../data/backgrounds'
import { Links } from '../data/links'
import { Link, RELATION_TYPES} from '../data/link'

export function Canvas() {
  const { node: active, mode, isDarkMode, isMobile, setNode, dataLoader, relationMode, baseNode, setBaseNode } = useContext(ControlContext)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const stateRef = useRef({
    mode,
    isDarkMode,
    isMobile,
    active,
    activeLinks: [] as Link[],
    animator: new Animator(),
    renderer: undefined as unknown as Renderer
  })
  useEffect(() => {
    // kick off the renderer and animator here
    // pass in a ref containing all of the state you need,
    // including dark mode, active node, etc.,
    // and change all entity managers to read from that.
    // get the context to change the ref when state changes.
    // that way, this component should never re-render.
    const renderer = new Renderer(canvasRef.current)
    const nodes = new Nodes(dataLoader, renderer, stateRef.current)
    const links = new Links(dataLoader, renderer, stateRef.current)
    const backgrounds = new Backgrounds(dataLoader, renderer, stateRef.current)
    stateRef.current.renderer = renderer
    stateRef.current.animator.events.on('tick', event => {
      renderer.clear(stateRef.current.isDarkMode)
      backgrounds.draw(event.t)
      links.draw(event.t)
      nodes.draw(event.t)
    })
    console.debug('Canvas renderer set up done')
  }, [])
  useEffect(() => {
    stateRef.current.animator.reset()
    stateRef.current.animator.start()
  }, [mode])
  useEffect(() => {
    stateRef.current.active = active
    stateRef.current.mode = mode
    stateRef.current.isDarkMode = isDarkMode
    stateRef.current.isMobile = isMobile
  }, [active, mode, isDarkMode, isMobile])
  useEffect(() => {
    if (!active) {
      stateRef.current.activeLinks = []
    } else {
      stateRef.current.activeLinks = dataLoader.links.filter(link => (
        link.type === RELATION_TYPES.MARRIAGE || link.type === RELATION_TYPES.PARENT_CHILD
      ) && (
          link.source === active || link.target === active
        ))
      if (baseNode && active) {
        const { links } = active.getRelationTo(baseNode)
        const filtered = links.filter(l => !stateRef.current.activeLinks.find(ll => ll.source === l.source && ll.target === l.target)).map(l => new Link(l.source, l.target, RELATION_TYPES.ROUTE))
        stateRef.current.activeLinks.unshift(...filtered)
      }
    }
  }, [active, baseNode])
  const onClick = event => {
    // find the node
    const { x, y } = stateRef.current.renderer.translateToPixelSpace(event.clientX, event.clientY)
    const match = dataLoader.findNodeByPosition(x, y, 12 * 12)
    setNode?.(match)
    if (relationMode) {
      if (!baseNode || (baseNode && !match)) {
        setBaseNode?.(match)
      }
    }
  }
  return (
    <canvas ref={canvasRef} onClick={onClick}></canvas>
  )
}