import { dispatch } from 'd3'

export class Animator {
  protected startTime: number
  protected duration: number
  protected minFrameTime: number
  protected then: number
  protected started = false
  readonly events = dispatch<Animator>('tick')
  constructor(duration = 1500, fps = 60) {
    this.duration = duration
    this.minFrameTime = 1000 / fps
  }
  start() {
    if (!this.started) {
      this.started = true
      window.requestAnimationFrame(now => this.then = now)
      window.requestAnimationFrame(this.tick)
    }
  }
  reset() {
    this.startTime = performance.now()
    this.events.call('tick', this, { t: 0 })
  }
  protected tick = (now: number) => {
    window.requestAnimationFrame(this.tick)
    const elapsed = now - this.then
    if (elapsed >= this.minFrameTime) {
      this.then = now
      const normT = Math.min(1, (now - this.startTime) / this.duration)
      this.events.call('tick', this, { t: normT })
    }
  }
}