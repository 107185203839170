import React, { useContext } from 'react'
import { ControlContext } from './provider'
import { timeMonth } from 'd3'
import { formatDate } from '../utils'

export function InfoPanel() {
  const { node, isMobile, baseNode } = useContext(ControlContext)
  const relation = baseNode ? node.getRelationStringTo(baseNode) : ''
  return node ? (
    <>
      <div id='heading'>{node.person.name}</div>
      <Info label='Nickname'>{node.person.nickname}</Info>
      <Info label='Born'>
        {node.person.birthDate && (
          <>
            {formatDate(node.person.birthDate, isMobile)}
            {node.person.birthDate && !node.person.deathDate && (
              yearsOld(node.person.birthDate)
            )}
          </>
        )}
      </Info>
      <Info label='Died'>
        {node.person.deathDate && (
          <>
            {formatDate(node.person.deathDate, isMobile)}
            {node.person.birthDate && node.person.deathDate && (
              yearsOld(node.person.birthDate, node.person.deathDate)
            )}
          </>
        )}
      </Info>
      <Info label='Chinese Zodiac Sign'>{node.person.chineseZodiacSign}</Info>
      {baseNode && relation && relation !== 'self' && relation !== 'unknown' && (
        <Info label='Relation'>{relation} of {baseNode.person.shortName}</Info>
      )}
    </>
  ) : undefined
}

function Info({ label, children }: { label: string, children: React.ReactNode }) {
  return children ? (
    <div>
      <span className='label'>{label}: </span>
      <span>{children}</span>
    </div>
  ) : undefined
}

function yearsOld(start: Date, end = new Date()) {
  let yearsOld: string | number = Math.floor(timeMonth.count(start, end) / 12)
  const suffix = yearsOld <= 1 ? 'year old' : 'years old'
  if (yearsOld < 1) yearsOld = '<1'
  return ` (${yearsOld} ${suffix})`
}