import { color, interpolateRainbow } from 'd3'
import { type DataLoader } from "./data/dataloader"
import { type Link, RELATION_TYPES } from './link'
import { type Renderer } from "../ui/renderer"
import { type MinimalControls } from '../utils'

export class Links {
  protected dataLoader: DataLoader
  protected renderer: Renderer
  protected controls: MinimalControls
  protected static lineDashMap: Record<RELATION_TYPES, number[]> = {
    [RELATION_TYPES.ROUTE]: [4, 4],
    [RELATION_TYPES.MARRIAGE]: [2, 2],
    [RELATION_TYPES.PARENT_CHILD]: [],
    [RELATION_TYPES.SIBLING]: [8, 8],
    [RELATION_TYPES.PARENT_CHILD_IN_LAW]: [4, 8, 12, 8],
  }
  constructor(dataLoader: DataLoader, renderer: Renderer, controls: MinimalControls) {
    this.dataLoader = dataLoader
    this.renderer = renderer
    this.controls = controls
  }
  draw(normT: number) {
    if (normT === 1) {
      const { line } = this.renderer
      for (const link of this.controls.activeLinks) {
        const { source, target } = link
        line(source.x, source.y, target.x, target.y, ctx => {
          const dash = Links.lineDashMap[link.type] || []
          ctx.setLineDash(dash)
          ctx.strokeStyle = this.getColour(link)
          ctx.lineWidth = 1
          ctx.fillStyle = 'transparent'
        })
      }
    }
  }
  protected getColour(link: Link) {
    const mult = this.controls.isDarkMode ? 0 : 0.4
    const { mode } = this.controls
    const { maxGen } = this.dataLoader
    if (mode === 'generation' || mode === 'birthDecade') {
      const gen = link.source.generation
      if (gen > 0) {
        if (mode === 'birthDecade') {
          const decade = this.dataLoader.decades.indexOf(link.source.person.birthDecade)
          if (decade > -1) {
            const colour = color(interpolateRainbow(decade / maxGen))?.darker(mult)
            if (colour) {
              // colour.opacity = this.controls.activeLinks.includes(link) ? 1 : 0.2
              colour.opacity = link.type === RELATION_TYPES.ROUTE ? 0.66 : 1
              return colour.toString()
            }
          }
        } else {
          const colour = color(interpolateRainbow(gen / maxGen))?.darker(mult)
          if (colour) {
            // colour.opacity = this.controls.activeLinks.includes(link) ? 1 : 0.2
            colour.opacity = link.type === RELATION_TYPES.ROUTE ? 0.66 : 1
            return colour.toString()
          }
        }
      }
    }
    return 'transparent'
  }
}