import { type Node } from "./node"

export class Link {
  readonly source: Node
  readonly target: Node
  readonly type: RELATION_TYPES
  constructor(source: Node, target: Node, type: RELATION_TYPES) {
    this.source = source
    this.target = target
    this.type = type
  }
  toString() {
    return `[Link: ${this.source.person} => ${this.target.person}]`
  }
}

export enum RELATION_TYPES {
  PARENT_CHILD,
  MARRIAGE,
  SIBLING,
  PARENT_CHILD_IN_LAW,
  ROUTE,
}